<template>
  <RevDrawer
    :close-button-label="i18n(dsTranslations.close)"
    :name="MODAL_NAMES.SUMMARY_SERVICE_FEE"
    :title="i18n(modalTitle)"
  >
    <template #trigger="{ open }">
      <RevLink @click="open">
        <span class="body-2 block text-left">
          {{ i18n(modalTrigger) }}
        </span>
      </RevLink>
    </template>
    <template #body>
      <div class="flex h-full flex-col justify-between">
        <div>
          <RevIllustration
            alt=""
            class="w-full"
            :height="158"
            src="/img/checkout/qualityAssuranceModal.svg"
            :width="328"
          />
          <div class="mt-32">
            <h3 class="heading-3">
              {{ i18n(translations.alternativeTitle) }}
            </h3>
            <div class="body-1 mt-12">
              {{ i18n(translations.alternativeDescription) }}
            </div>
          </div>

          <ContentRow
            class="mt-24"
            :labels="[i18n(translations.alternativeFirstSectionLabel)]"
            :title="i18n(translations.alternativeFirstSectionTitle)"
          >
            <IconQuality />
          </ContentRow>
          <ContentRow
            class="mt-24"
            :hyperlink="warrantyLink"
            :labels="[i18n(translations.alternativeSecondSectionLabel)]"
            :title="i18n(translations.alternativeSecondSectionTitle)"
          >
            <IconHeadset />
          </ContentRow>
          <ContentRow
            class="mt-24"
            :labels="[i18n(translations.alternativeThirdSectionLabel)]"
            :title="i18n(translations.alternativeThirdSectionTitle)"
          >
            <IconLightbulb />
          </ContentRow>
        </div>
      </div>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { useZendeskFaqBaseUrl } from '#imports'
import { computed } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevDrawer } from '@ds/components/Drawer'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { IconHeadset } from '@ds/icons/IconHeadset'
import { IconLightbulb } from '@ds/icons/IconLightbulb'
import { IconQuality } from '@ds/icons/IconQuality'
import { dsTranslations } from '@ds/translations'

import ContentRow from '~/scopes/product/components/ContentRow/ContentRow.vue'

import { MODAL_NAMES } from '../../config/constants'

import translations from './ServiceFeeModal.translations'

const i18n = useI18n()
const experiments = useExperiments()

const { zendeskFaqBaseUrl } = useZendeskFaqBaseUrl()

const warrantyLink = computed(() => {
  return {
    link: zendeskFaqBaseUrl.concat('/categories/360002230860'),
    text: i18n(translations.link),
  }
})

const modalTitle = computed(() => {
  return experiments['experiment.cartServiceFeeContent'] === 'qualityPact'
    ? translations.modalTitleABTest
    : translations.modalTitle
})

const modalTrigger = computed(() => {
  return experiments['experiment.cartServiceFeeContent'] === 'qualityPact'
    ? translations.modalTriggerABTest
    : translations.modalTrigger
})
</script>
